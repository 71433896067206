import React from "react"
import AreasSecurityAdvantagesSectionStyle from "./AreasSecurityAdvantagesSectionStyle.module.styl"
import Advantage from "../../../../common/packages/advantage/Advantage"

const AreasSecurityAdvantagesSection = props => {
  return (
    <section className={AreasSecurityAdvantagesSectionStyle.advantages_section}>
      <div className={AreasSecurityAdvantagesSectionStyle.advantages_content}>
        <h2>Защо да изберете тази услуга ?</h2>
        <div className="row">
          <div className="col-lg-3 col-md-6 col-12">
            <Advantage number={1} advantage={"Жива охрана"} />{" "}
          </div>
          <div className="col-lg-3 col-md-6 col-12">
            <Advantage number={2} advantage={"Камери и Термо камери"} />{" "}
          </div>{" "}
          <div className="col-lg-3 col-md-6 col-12">
            <Advantage number={3} advantage={"Уреди за нощно виждане"} />{" "}
          </div>{" "}
          <div className="col-lg-3 col-md-6 col-12">
            <Advantage number={4} advantage={"Дрон"} />{" "}
          </div>{" "}
        </div>
      </div>
    </section>
  )
}

export default AreasSecurityAdvantagesSection
