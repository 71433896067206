import React from "react"
import AreasSecurityHeroSectionStyle from "./AreasSecurityHeroSectionStyle.module.styl"
import Img from "gatsby-image"

const AreasSecurityheroSection = ({ areasSecurityHeroSectionImage }) => {
  return (
    <section className={AreasSecurityHeroSectionStyle.hero_section}>
      <Img
        className={AreasSecurityHeroSectionStyle.image}
        fluid={areasSecurityHeroSectionImage[0].node.childImageSharp.fluid}
      />
      <div className={AreasSecurityHeroSectionStyle.title_wrapper}>
        <h1>Охрана на земеделски площи</h1>
      </div>
    </section>
  )
}

export default AreasSecurityheroSection
