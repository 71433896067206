import React from "react"
import AreasSecurityInfoSectionStyle from "./AreasSecurityInfoSectionStyle.module.styl"
import Img from "gatsby-image"

const AreasSecurityInfoSection = ({ areasSecurityInfoSectionImage }) => {
  return (
    <section className={AreasSecurityInfoSectionStyle.info_section}>
      <div className={AreasSecurityInfoSectionStyle.intro_section_content}>
        <Img
          className={AreasSecurityInfoSectionStyle.intro_img}
          fluid={areasSecurityInfoSectionImage[0].node.childImageSharp.fluid}
        />
        <div className={AreasSecurityInfoSectionStyle.intro_text}>
          <span>Охраната на земеделски площи </span>, земеделска продукция и
          животновъдни обекти е услуга предлагана от „Спешъл Сикюрити Груп“ ООД,
          която придобива все по-широк интерес. Селското стопанство е един от
          основните отрасли на българската икономика и като такъв има нужда от
          адекватни мерки за охрана, които не трябва да се ограничават само с
          жива, патрулна охрана. Ние предлагаме иновативни методи, които се
          осъществяват с модерно оборудване и са съобразени с индивидуалните
          изискванията на всеки наш клиент.
        </div>
      </div>
    </section>
  )
}

export default AreasSecurityInfoSection
