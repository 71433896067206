import React from "react"
import AreasSecurityheroSection from "./areasSecurityHeroSection/AreasSecurityheroSection"
import AreasSecurityInfoSection from "./areasSecurityInfoSection/AreasSecurityInfoSection"
import AreasSecurityAdvantagesSection from "./areasSecurityAdvantagesSection/AreasSecurityAdvantagesSection"
import AreasSecuritySliderSection from "./areasSecuritySliderSection/AreasSecuritySliderSection"

const AreasSecurityTemplate = ({
  areasSecurityInfoSectionImage,
  areasSecurityHeroSectionImage,
}) => {
  return (
    <>
      <AreasSecurityheroSection
        areasSecurityHeroSectionImage={areasSecurityHeroSectionImage}
      />
      <AreasSecurityInfoSection
        areasSecurityInfoSectionImage={areasSecurityInfoSectionImage}
      />
      <AreasSecurityAdvantagesSection />
      <AreasSecuritySliderSection />
    </>
  )
}

export default AreasSecurityTemplate
