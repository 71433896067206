import React from "react"
import AreasSecuritySliderSectionStyle from "./AreasSecuritySliderSectionStyle.module.styl"
import Slider from "react-slick"
import ServiceSliderCard from "../../../../common/packages/serviceSliderCard/ServiceSliderCard"
import SystemsImage from "../../../../assets/images/closeup-cctv-camera-wall-min.jpg"
import SODImage from "../../../../assets/images/Rectangle 62@2x-min.png"
import EventsImage from "../../../../assets/images/Rectangle 63@2x-min.png"
import SecurityGuardsImage from "../../../../assets/images/Rectangle 59@2x-min.png"
import VideoImage from "../../../../assets/images/Rectangle 61@2x-min.png"

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

const AreasSecuritySliderSection = props => {
  return (
    <section className={AreasSecuritySliderSectionStyle.slider_section}>
      <div className={AreasSecuritySliderSectionStyle.slider_section_content}>
        <h2>Рагледайте останалите ни услуги</h2>
        <Slider {...settings}>
          <ServiceSliderCard
            service_image={SystemsImage}
            service_name={"Охранителни системи"}
            service_url={"/ohranitelni-sistemi"}
          />
          <ServiceSliderCard
            service_url={"/signalno-ohranitelna-deinost"}
            service_image={SODImage}
            service_name={"СОД"}
          />
          <ServiceSliderCard
            service_image={EventsImage}
            service_url={'/ohrana-na-meropriatia'}
            service_name={"Охрана на мероприятия"}
          />
          <ServiceSliderCard
            service_url={'/fizicheska-ohrana'}
            service_image={SecurityGuardsImage}
            service_name={"Физическа охрана"}
          />
          <ServiceSliderCard
            service_url={"/videonabludenie"}
            service_image={VideoImage}
            service_name={"Видеонаблюдение"}
          />
        </Slider>
      </div>
    </section>
  )
}

export default AreasSecuritySliderSection
