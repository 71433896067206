import React from "react"
import Layout from "../common/layout/layout"
import SEO from "../common/seo/seo"
import AreasSecurityTemplate from "../modules/services/areasSecurity/AreasSecurity.template"
import { graphql } from "gatsby"

const OhranaNaZemedelskiPloshti = ({ data }) => {
  return (
    <Layout>
      <SEO
        title={"Охрана на земеделски площи"}
        keywords={
          "СОТ Ямбол, охрана Ямбол, СОД ямбол, Видеонаблюдение,охрана на обекти Ямбол, физическа охрана Ямбол, цени на СоТ Ямбол"
        }
      />
      <AreasSecurityTemplate
        areasSecurityHeroSectionImage={data.areasSecurityHeroSectionImage.edges}
        areasSecurityInfoSectionImage={data.areasSecurityInfoSectionImage.edges}
      />
    </Layout>
  )
}

export default OhranaNaZemedelskiPloshti
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    areasSecurityHeroSectionImage: allFile(
      filter: { relativeDirectory: { eq: "areasSecurityHeroSectionImage" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    areasSecurityInfoSectionImage: allFile(
      filter: { relativeDirectory: { eq: "areasSecurityInfoSectionImage" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
