import React from "react"
import AdvantageStyle from "./AdvantageStyle.module.styl"

const Advantage = ({ number, advantage }) => {
  return (
    <div className={AdvantageStyle.advantage}>
      <div className={AdvantageStyle.number_holder}>{number}</div>
      <div className={AdvantageStyle.advantage_holder}>{advantage}</div>
    </div>
  )
}

export default Advantage
